import "../styles/globals.css";
import { Layout } from "../components/Layout";
import useStore from "../appointment-redux/store";
import { Provider } from "react-redux";
import type { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect } from "react";
import * as gtag from "../lib/gtag";
import { hotjar } from "react-hotjar";

declare global {
  interface Window {
    GA_TRACKING_ID: string;
  }
}

const HOTJAR_ID =
  process.env.NEXT_PUBLIC_HOTJAR_ID !== undefined
    ? parseInt(process.env.NEXT_PUBLIC_HOTJAR_ID)
    : 2782607;

function MyApp({ Component, pageProps }: AppProps): React.ReactNode {
  const router = useRouter();

  function InitializeHotjar() {
    useEffect(() => {
      hotjar.initialize(HOTJAR_ID, 6);
    }, []);
    return null;
  }

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      /* invoke analytics function only for production */
      if (window && window.GA_TRACKING_ID) {
        gtag.pageview(url, window.GA_TRACKING_ID);
      }
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  const store = useStore();
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Provider store={store}>
        <Layout footer={pageProps.pageContent.footer}>
          <Component {...pageProps} />
        </Layout>
      </Provider>
      <InitializeHotjar />
    </>
  );
}
export default MyApp;
