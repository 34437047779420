import { createAsyncThunk } from "@reduxjs/toolkit";
import { genericErrorMessage } from "../../../constants/errorConstants";
import adminServices from "../../../services/admin/adminServices";

export const loginAdmin = createAsyncThunk(
  "admin/loginAdmin",
  async (adminData: { username: string; password: string }) => {
    let data = { token: "" };
    try {
      data = await adminServices.authenticationAdmin(
        adminData.username,
        adminData.password
      );
      localStorage.setItem("adminToken", data.token);
      return data.token;
    } catch (err) {
      console.error(err);
      window.alert(genericErrorMessage);
      return "";
    }
  }
);
