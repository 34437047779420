import axios from "axios";
import {
  CustomerDto,
  AvailableAppointmentsDto,
  MakeAppointmentDto,
  UserAppointmentDto,
} from "./customerTypes";

const baseUrl = process.env.NEXT_PUBLIC_BACKEND_URL + "/v1/customers";

const registerAnonymous = async (): Promise<CustomerDto> => {
  const response = await axios
    .post(`${baseUrl}/register-anonymous`)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
  return response;
};

const getCustomer = (
  customerToken: string
): Promise<CustomerDto> | Promise<string> => {
  return axios
    .get(`${baseUrl}/${customerToken}`)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
};

const getAvailableAppointments = (
  customerToken: string
): Promise<AvailableAppointmentsDto> => {
  return axios
    .get(`${baseUrl}/${customerToken}/appointments/available`, {
      headers: {
        accept: "application/json",
      },
      data: {},
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
};

const makeAppointment = (
  customerToken: string,
  data: MakeAppointmentDto
): Promise<MakeAppointmentDto> => {
  return axios({
    method: "post",
    url: `${baseUrl}/${customerToken}/appointments`,
    headers: {
      accept: "application/json",
    },
    data: {
      localDateTimeFrom: data.localDateTimeFrom,
      localDateTimeTo: data.localDateTimeTo,
      name: data.name,
      phone: data.phone,
      email: data.email,
      employeeId: data.employeeId,
    },
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
};

const cancelAppointment = (
  customerToken: string
): Promise<MakeAppointmentDto> => {
  return axios({
    method: "post",
    url: `${baseUrl}/${customerToken}/appointments/current/cancel`,
    headers: {
      accept: "application/json",
    },
    data: {},
  })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
};

const getAppointment = (customerToken: string): Promise<UserAppointmentDto> => {
  return axios
    .get(`${baseUrl}/${customerToken}/appointments/current`, {
      headers: {
        accept: "application/json",
      },
      data: {},
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
};

const allCustomerServices = {
  registerAnonymous,
  getCustomer,
  getAvailableAppointments,
  makeAppointment,
  cancelAppointment,
  getAppointment,
};

export default allCustomerServices;
