import Navigation from "../components/Navigation";
import Footer, { FooterType } from "./Sections/Footer/Footer";

interface Props {
  children: React.ReactNode;
  footer: FooterType;
}

export const Layout: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <div className="flex flex-col h-screen justify-between">
      <Navigation />
      <main className="mt-12">{props.children}</main>
      {props.footer ? <Footer footer={props.footer} /> : null}
    </div>
  );
};
