import Link from "next/link";
import React, { ReactNode } from "react";

interface MenuItemProps {
  isMenuVisibleHandler: (value: boolean) => void;
  onClickLocation: string;
  children: ReactNode;
}

const MenuItem: React.FC<MenuItemProps> = (props) => {
  return (
    <Link href={props.onClickLocation}>
      <a
        className="flex items-center justify-center w-48 h-10 mt-3 text-sm font-medium text-center text-white uppercase cursor-pointer font-oswald hover:bg-accent leading-lnh20 desktop:leading-lnh26"
        onClick={() => {
          props.isMenuVisibleHandler(false);
        }}
      >
        {props.children}
      </a>
    </Link>
  );
};

export default MenuItem;
