import FooterComponentRouter, {
  footerElementsType,
} from "./FooterComponentRouter";

export interface footerContainer {
  footerComponents: footerElementsType[];
}
export interface FooterType {
  footerContainers: footerContainer[];
}

export interface FooterProps {
  footer: FooterType;
}

const Footer: React.FunctionComponent<FooterProps> = (props) => {
  return (
    <footer
      className="w-full px-4 mt-8 text-white align-bottom bg-flatblack py-11"
      id="contact"
    >
      <div className="flex flex-col mx-auto desktop:flex-wrap desktop:max-w-container">
        {props.footer.footerContainers.map(
          (el: footerContainer, index: number) => {
            return (
              <div
                key={index}
                className="flex flex-col justify-start desktop:w-1/2 "
              >
                <FooterComponentRouter footerElement={el.footerComponents} />
              </div>
            );
          }
        )}
      </div>
    </footer>
  );
};
export default Footer;
