import { createAsyncThunk } from "@reduxjs/toolkit";
import { genericErrorMessage } from "../../../constants/errorConstants";
import adminServices from "../../../services/admin/adminServices";

export const getAppointment = createAsyncThunk(
  "admin/getAppointment",
  async (tokens: { adminToken: string; userToken: string }) => {
    try {
      const data = await adminServices.getAppointment(
        tokens.adminToken,
        tokens.userToken
      );
      return data;
    } catch (err) {
      console.error(err);
      window.alert(genericErrorMessage);
    }
  }
);
