import { createAsyncThunk } from "@reduxjs/toolkit";
import { genericErrorMessage } from "../../../constants/errorConstants";
import customerService from "../../../services/customer/customerService";

export const cancelAppointment = createAsyncThunk(
  "customer/cancelAppointment",
  async (userToken: string) => {
    try {
      await customerService.cancelAppointment(userToken);
      return true;
    } catch (err) {
      console.error(err);
      window.alert(genericErrorMessage);
      return false;
    }
  }
);
