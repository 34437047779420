import { useState } from "react";
import { MdMenu } from "react-icons/md";

import Menu from "./Menu";

const Navigation: React.FunctionComponent = () => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  return (
    <>
      <nav className="fixed top-0 w-full h-12 bg-flatblack z-zMax">
        <MdMenu
          className="w-6 h-6 text-white absolute left-[22px] top-3 cursor-pointer"
          onClick={() => {
            setIsMenuVisible(true);
          }}
        />
      </nav>

      <Menu
        isMenuVisibleHandler={(value: boolean) => {
          setIsMenuVisible(value);
        }}
        isMenuVisible={isMenuVisible}
      />
    </>
  );
};

export default Navigation;
