import { PayloadAction } from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/internal";
import { UserAppointmentDto } from "../../services/customer/customerTypes";
import { userDataInterface, HasAppointmentEnum } from "./appointmentTypes";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const checkUserAppointmentFulfilled = (
  state: WritableDraft<userDataInterface>,
  action: PayloadAction<
    UserAppointmentDto | null | undefined,
    string,
    { arg: string; requestId: string; requestStatus: "fulfilled" },
    never
  >
) => {
  if (action.payload) {
    state.user.hasAppointment = HasAppointmentEnum.HasAppointment;
    state.user.userAppointment.date = action.payload.date;
    state.user.userAppointment.time = action.payload.time;
    state.user.email = action.payload.email;
    state.user.name = action.payload.name;
    state.user.phone = action.payload.phone;
  } else {
    state.user.hasAppointment = HasAppointmentEnum.NoAppointment;
    state.appointment.makingAppointment = false;
  }
};

export default checkUserAppointmentFulfilled;
