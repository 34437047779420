import { createAsyncThunk } from "@reduxjs/toolkit";
import { genericErrorMessage } from "../../../constants/errorConstants";
import customerService from "../../../services/customer/customerService";
import { MakeAppointmentDto } from "../../../services/customer/customerTypes";

export const makeAppointment = createAsyncThunk(
  "customer/makeAppointment",
  async (data: { userToken: string | null; body: MakeAppointmentDto }) => {
    try {
      if (data.userToken) {
        await customerService.makeAppointment(data.userToken, data.body);
        localStorage.setItem("userName", data.body.name);
        localStorage.setItem("userPhone", data.body.phone);
        localStorage.setItem("userEmail", data.body.email);
        return true;
      }
    } catch (err) {
      console.error(err);
      window.alert(genericErrorMessage);
      return false;
    }
  }
);
