import AbstractComponent from "../../../strapi_types/AbstractComponent";

interface footerElementsType extends AbstractComponent {
  elementText: string;
}

interface FooterComponentRouterProps {
  footerElement: footerElementsType[];
}

const FooterComponentRouter: React.FunctionComponent<
  FooterComponentRouterProps
> = (props) => {
  return (
    <>
      {props.footerElement.map((el, id: number) => {
        if (el.__component === "footer-components.title") {
          return (
            <p
              className="mt-8 mb-2 text-lg font-medium uppercase desktop:text-2xl font-oswald leading-lnh26 desktop:leading-lnh35"
              key={id}
            >
              {el.elementText}
            </p>
          );
        } else if (el.__component === "footer-components.description") {
          return (
            <p
              className="mb-1 text-base font-light font-oswald leading-lnh23 desktop:leading-lnh29 desktop:text-xl"
              key={id}
            >
              {el.elementText}
            </p>
          );
        } else return <></>;
      })}
    </>
  );
};

export type { footerElementsType };
export default FooterComponentRouter;
