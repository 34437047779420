import { createAsyncThunk } from "@reduxjs/toolkit";
import customerServices from "../../../services/customer/customerService";

export const checkUserAppointment = createAsyncThunk(
  "appointments/checkUserAppointment",
  async (token: string) => {
    try {
      const data = await customerServices.getAppointment(token);
      if (data.date) {
        return data;
      }
    } catch (err) {
      return null;
    }
  }
);
