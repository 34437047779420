import React from "react";
import { MdClose } from "react-icons/md";
import MenuItem from "./MenuItem";
import classNames from "classnames";
import router from "next/router";

interface MenuProps {
  isMenuVisibleHandler: (value: boolean) => void;
  isMenuVisible: boolean;
}

const Menu: React.FC<MenuProps> = (props) => {
  const styleVisible =
    "fixed top-0 flex flex-col items-center justify-center z-zMenu bg-flatblack w-fullvw h-fullvh ";

  const style = classNames(styleVisible, { hidden: !props.isMenuVisible });

  return (
    <div className={style}>
      <MenuItem
        isMenuVisibleHandler={() => {
          props.isMenuVisibleHandler(false);
          router.push("/");
        }}
        onClickLocation="/"
      >
        Početna
      </MenuItem>
      <MenuItem
        isMenuVisibleHandler={() => {
          props.isMenuVisibleHandler(false);
        }}
        onClickLocation="/appointment"
      >
        Zakaži termin
      </MenuItem>
      <MenuItem
        isMenuVisibleHandler={() => {
          props.isMenuVisibleHandler(false);
        }}
        onClickLocation="/#price-list"
      >
        Cjenovnik
      </MenuItem>
      <MenuItem
        isMenuVisibleHandler={() => {
          props.isMenuVisibleHandler(false);
        }}
        onClickLocation="/#gallery"
      >
        Galerija
      </MenuItem>
      <MenuItem
        isMenuVisibleHandler={() => {
          props.isMenuVisibleHandler(false);
        }}
        onClickLocation="/#contact"
      >
        Kontakt
      </MenuItem>
      <MdClose
        className="fixed w-6 h-6 text-white cursor-pointer top-4 right-4 z-zMenu"
        onClick={() => {
          props.isMenuVisibleHandler(false);
        }}
      />
    </div>
  );
};

export default Menu;
