import axios from "axios";
import {
  EmployeeAppointmentCalendarDto,
  SelectedAppointmentDto,
} from "./adminTypes";

const baseUrl = process.env.NEXT_PUBLIC_BACKEND_URL + "/v1";

const authenticationAdmin = (
  username: string,
  password: string
): Promise<{ token: string }> => {
  return axios({
    method: "post",
    url: `${baseUrl}/authentication`,
    headers: {
      accept: "application/json",
    },
    data: {
      username,
      password,
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const getAppointmentCalendar = (
  token: string
): Promise<EmployeeAppointmentCalendarDto> => {
  return axios({
    method: "get",
    url: `${baseUrl}/employee/appointments`,
    headers: {
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: {},
  })
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
};

const getAppointment = (
  token: string,
  userToken: string
): Promise<SelectedAppointmentDto> => {
  return axios({
    method: "get",
    url: `${baseUrl}/employee/appointments/${userToken}`,
    headers: {
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: {},
  })
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
};

const makeAppointment = (
  token: string,
  data: SelectedAppointmentDto
): Promise<SelectedAppointmentDto> => {
  return axios({
    method: "post",
    url: `${baseUrl}/employee/appointments/`,
    headers: {
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: {
      date: data.date,
      startTime: data.startTime,
      endTime: data.endTime,
      name: data.name,
      phone: data.phone,
      email: data.email,
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
};

const cancelAppointment = (
  adminToken: string,
  userToken: string
): Promise<SelectedAppointmentDto> => {
  return axios({
    method: "post",
    url: `${baseUrl}/employee/appointments/${userToken}/cancel`,
    headers: {
      accept: "application/json",
      Authorization: `Bearer ${adminToken}`,
    },
    data: {},
  })
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
};

const disableAppointment = (
  token: string,
  data: Partial<SelectedAppointmentDto>
): Promise<SelectedAppointmentDto> => {
  return axios({
    method: "post",
    url: `${baseUrl}/employee/appointments/disable`,
    headers: {
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: {
      date: data.date,
      startTime: data.startTime,
      endTime: data.endTime,
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
};
const enableAppointment = (
  token: string,
  data: Partial<SelectedAppointmentDto>
): Promise<SelectedAppointmentDto> => {
  return axios({
    method: "post",
    url: `${baseUrl}/employee/appointments/enable`,
    headers: {
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: {
      date: data.date,
      startTime: data.startTime,
      endTime: data.endTime,
    },
  })
    .then((response) => {
      return response.data;
    })
    .catch(function (error) {
      throw error;
    });
};

const allAdminServices = {
  authenticationAdmin,
  getAppointmentCalendar,
  getAppointment,
  makeAppointment,
  cancelAppointment,
  disableAppointment,
  enableAppointment,
};

export default allAdminServices;
