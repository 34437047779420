export interface adminDataInterface {
  admin: {
    token: string;
    adminStatus: AdminStatusEnum;
  };
  appointments: {
    appointmentsPerDays: [] | AppointmentsPerDaysDto[];
    loadingAppointmentData: boolean;
    dayIndex: number;
    timeIndex: number;
    customerForm: SelectedAppointmentDto;
    reservedAppointmentData: SelectedAppointmentDto;
  };
}

export enum AdminStatusEnum {
  NotChecked = "NotChecked",
  Logged = "Logged",
  WrongCredentials = "WrongCredentials",
}
export interface EmployeeAppointmentCalendarDto {
  appointmentsPerDays: AppointmentsPerDaysDto[] | [];
}

export interface AppointmentsPerDaysDto {
  localDate: string;
  appointmentSlotDtos: EmployeeAppointmentSlotDto[];
}

export enum AdminAppointmentTimeTypeEnum {
  DISABLED = "DISABLED",
  AVAILABLE = "AVAILABLE",
  OCCUPIED = "OCCUPIED",
}

export interface EmployeeAppointmentSlotDto {
  startTime: string;
  endTime: string;
  appointmentContractId: string;
  appointmentSlotStatus: AdminAppointmentTimeTypeEnum;
}

export interface SelectedAppointmentDto {
  date: string;
  startTime: string;
  endTime: string;
  name: string;
  phone: string;
  email: string;
}
