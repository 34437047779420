import { createSlice } from "@reduxjs/toolkit";
import { fetchAppointments } from "./extra-reducers/fetchAppointments";
import { HasAppointmentEnum, userDataInterface } from "./appointmentTypes";
import { authenticateUser } from "./extra-reducers/authenticateUser";
import { checkUserAppointment } from "./extra-reducers/checkUserAppointment";
import checkUserAppointmentFulfilled from "./checkUserAppointmentFulfilled";
import { cancelAppointment } from "./extra-reducers/cancelAppointment";
import { makeAppointment } from "./extra-reducers/makeAppointment";
import { timeStateFormater } from "./timeStateFormater";

const initialState: userDataInterface = {
  user: {
    userToken: null,
    hasAppointment: HasAppointmentEnum.NotChecked,
    userAppointment: {
      date: "",
      time: "",
    },
    name: "",
    phone: "",
    email: "",
  },
  appointment: {
    AvailableAppointmentsPerEmployee: [],
    loadingAppointmentData: true,
    makingAppointment: false,
    dayIndex: -1,
    timeIndex: -1,
    appointmentData: {
      localDateTimeFrom: "",
      localDateTimeTo: "",
      name: "",
      phone: "",
      email: "",
      employeeId: "",
    },
  },
};

export const userDataSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    changeDay: (state, action) => {
      state.appointment.timeIndex = -1;
      state.appointment.dayIndex = action.payload;
      timeStateFormater(state);
    },
    changeTime: (state, action) => {
      state.appointment.timeIndex = action.payload;
      //
      timeStateFormater(state);
    },
    changeName: (state, action) => {
      state.appointment.appointmentData.name = action.payload;
    },
    changePhone: (state, action) => {
      state.appointment.appointmentData.phone = action.payload;
    },
    changeEmail: (state, action) => {
      state.appointment.appointmentData.email = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchAppointments.fulfilled, (state, action) => {
      state.appointment.AvailableAppointmentsPerEmployee = action.payload;
      state.appointment.loadingAppointmentData = false;

      state.appointment.appointmentData.employeeId =
        state.appointment.AvailableAppointmentsPerEmployee[0].salonEmployeeId;
      timeStateFormater(state);
    });
    builder.addCase(authenticateUser.fulfilled, (state, action) => {
      state.user.userToken = action.payload;
    });
    builder.addCase(checkUserAppointment.fulfilled, (state, action) => {
      checkUserAppointmentFulfilled(state, action);
    });
    builder.addCase(cancelAppointment.fulfilled, (state, action) => {
      if (action.payload) {
        state.user.hasAppointment = HasAppointmentEnum.NotChecked;
      }
    });
    builder.addCase(makeAppointment.pending, (state) => {
      state.appointment.makingAppointment = true;
    });
    builder.addCase(makeAppointment.fulfilled, (state) => {
      state.user.hasAppointment = HasAppointmentEnum.NotChecked;
    });
  },
});

export const { changeDay, changeTime, changeName, changePhone, changeEmail } =
  userDataSlice.actions;

export default userDataSlice.reducer;
