import { createSlice } from "@reduxjs/toolkit";
import router from "next/router";
import {
  adminDataInterface,
  AdminStatusEnum,
} from "../../services/admin/adminTypes";
import {
  changeDayReducer,
  changeTimeReducer,
  getAppointmentFulfilled,
  loginAdminFulfilled,
} from "./adminDataReducers";
import { getAllAppointments } from "./extraReducers/getAllAppointments";
import { getAppointment } from "./extraReducers/getAppointment";
import { loginAdmin } from "./extraReducers/loginAdmin";

const initialState: adminDataInterface = {
  admin: {
    token: "",
    adminStatus: AdminStatusEnum.NotChecked,
  },
  appointments: {
    appointmentsPerDays: [],
    loadingAppointmentData: true,
    dayIndex: 0,
    timeIndex: 0,
    customerForm: {
      date: "",
      startTime: "",
      endTime: "",
      name: "",
      phone: "",
      email: "",
    },
    reservedAppointmentData: {
      date: "",
      startTime: "",
      endTime: "",
      name: "",
      phone: "",
      email: "",
    },
  },
};

export const adminDataSlice = createSlice({
  name: "adminData",
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.admin.token = action.payload;
    },
    changeDay: (state, action) => {
      changeDayReducer(state, action);
    },
    changeTime: (state, action) => {
      changeTimeReducer(state, action);
    },
    changeName: (state, action) => {
      state.appointments.customerForm.name = action.payload;
    },
    changePhone: (state, action) => {
      state.appointments.customerForm.phone = action.payload;
    },
    changeEmail: (state, action) => {
      state.appointments.customerForm.email = action.payload;
    },
    changeAdminToken: (state, action) => {
      state.admin.token = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(loginAdmin.fulfilled, (state, action) => {
      loginAdminFulfilled(state, action);
    });
    builder.addCase(getAllAppointments.pending, (state) => {
      state.appointments.loadingAppointmentData = true;
    });
    builder.addCase(getAllAppointments.fulfilled, (state, action) => {
      state.appointments.appointmentsPerDays = action.payload;
      state.appointments.loadingAppointmentData = false;
    });
    builder.addCase(getAllAppointments.rejected, () => {
      localStorage.removeItem("adminToken");
      router.push("/login");
    });

    builder.addCase(getAppointment.fulfilled, (state, action) => {
      getAppointmentFulfilled(state, action);
    });
  },
});

export const {
  setToken,
  changeDay,
  changeTime,
  changeName,
  changePhone,
  changeEmail,
  changeAdminToken,
} = adminDataSlice.actions;

export default adminDataSlice.reducer;
