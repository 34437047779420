import { createAsyncThunk } from "@reduxjs/toolkit";
import { genericErrorMessage } from "../../../constants/errorConstants";
import customerServices from "../../../services/customer/customerService";
import { AvailableAppointmentsDto } from "../../../services/customer/customerTypes";

export const fetchAppointments = createAsyncThunk(
  "appointments/fetchAppointments",
  async (token: string) => {
    try {
      const data = await customerServices.getAvailableAppointments(token);

      return filterAvailableAppointments(data).appointmentsPerEmployee;
    } catch (err) {
      console.error(err);
      window.alert(genericErrorMessage);
      return [];
    }
  }
);

function filterAvailableAppointments(data: AvailableAppointmentsDto) {
  for (const perEmployee of data.appointmentsPerEmployee) {
    for (const appointmentDateTimes of perEmployee.appointmentDateTimes) {
      const filteredTimes = [
        "07:00:00",
        "07:30:00",
        "08:00:00",
        "08:30:00",
        "17:00:00",
        "17:30:00",
        "18:00:00",
        "18:30:00",
        ...getFilteredTimesIfSaturday(appointmentDateTimes.localDate),
      ];
      appointmentDateTimes.localTimeList =
        appointmentDateTimes.localTimeList.filter(
          (localTime) =>
            !filteredTimes.some((filtered) => filtered == localTime.startTime)
        );
    }

    perEmployee.appointmentDateTimes = perEmployee.appointmentDateTimes.filter(
      (adt) => adt.localTimeList.length > 0
    );
  }

  return data;
}

function getFilteredTimesIfSaturday(localDate: string): string[] {
  try {
    const date = new Date(localDate);
    return date.getDay() == 6 ? ["16:00:00", "16:30:00"] : [];
  } catch (e) {
    console.error("getFilteredTimesIfSaturday: ", e);
    return [];
  }
}
