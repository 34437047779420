import {
  AvailableAppointmentsPerEmployeeDto,
  MakeAppointmentDto,
} from "../../services/customer/customerTypes";

export interface AppointmentDataInterface {
  AvailableAppointmentsPerEmployee: AvailableAppointmentsPerEmployeeDto[] | [];
  loadingAppointmentData: boolean;
  makingAppointment: boolean;
  dayIndex: number;
  timeIndex: number;
  appointmentData: MakeAppointmentDto;
}

export enum HasAppointmentEnum {
  NotChecked = "NotChecked",
  HasAppointment = "HasAppointment",
  NoAppointment = "NoAppointment",
}

export interface UserDataInterface {
  userToken: null | string;
  hasAppointment: HasAppointmentEnum;
  userAppointment: UserAppointmentInterface;
  name: string;
  phone: string;
  email: string;
}

export interface userDataInterface {
  user: UserDataInterface;
  appointment: AppointmentDataInterface;
}

export interface UserAppointmentInterface {
  date: string;
  time: string;
}
