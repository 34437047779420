import { WritableDraft } from "immer/dist/internal";
import { userDataInterface } from "./appointmentTypes";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const timeStateFormater = (state: WritableDraft<userDataInterface>) => {
  if (state.appointment.dayIndex < 0 || state.appointment.timeIndex < 0) {
    return;
  }

  state.appointment.appointmentData.localDateTimeFrom =
    state.appointment.AvailableAppointmentsPerEmployee[0].appointmentDateTimes[
      state.appointment.dayIndex
    ].localDate +
    "T" +
    state.appointment.AvailableAppointmentsPerEmployee[0].appointmentDateTimes[
      state.appointment.dayIndex
    ].localTimeList[state.appointment.timeIndex].startTime;
  state.appointment.appointmentData.localDateTimeTo =
    state.appointment.AvailableAppointmentsPerEmployee[0].appointmentDateTimes[
      state.appointment.dayIndex
    ].localDate +
    "T" +
    state.appointment.AvailableAppointmentsPerEmployee[0].appointmentDateTimes[
      state.appointment.dayIndex
    ].localTimeList[state.appointment.timeIndex].endTime;
};
