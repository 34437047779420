/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createAsyncThunk } from "@reduxjs/toolkit";
import customerServices from "../../../services/customer/customerService";
import { changeEmail, changeName, changePhone } from "../userDataSlice";

const getUserTokenFromUrl = () => {
  if (typeof window !== "undefined") {
    const parsedUrl = new URL(window.location.href);
    return parsedUrl.searchParams.get("uuid");
  }
};

export const authenticateUser = createAsyncThunk(
  "users/authenticateUser",
  async (a: string, thunkAPI) => {
    if (localStorage.getItem("userName")) {
      thunkAPI.dispatch(changeName(localStorage.getItem("userName")));
    }
    if (localStorage.getItem("userEmail")) {
      thunkAPI.dispatch(changeEmail(localStorage.getItem("userEmail")));
    }
    if (localStorage.getItem("userPhone")) {
      thunkAPI.dispatch(changePhone(localStorage.getItem("userPhone")));
    }
    try {
      const token = localStorage.getItem("userToken");
      const urlToken = getUserTokenFromUrl();
      if (urlToken) {
        await customerServices.getCustomer(urlToken);
        localStorage.setItem("userToken", urlToken);
        return urlToken;
      } else if (!token) {
        const data = await customerServices.registerAnonymous();
        localStorage.setItem("userToken", data.token);
        return data.token;
      } else {
        await customerServices.getCustomer(token);
        return token;
      }
    } catch (err) {
      localStorage.removeItem("userToken");
      const data = await customerServices.registerAnonymous();
      localStorage.setItem("userToken", data.token);
      return data.token;
    }
  }
);
